.contact-form {
  .form-control, .form-control:focus, .dropdown{
    outline: none;
    height: 56px;
    padding: 17px 20px;
    border-radius: 3px;
    color: $th-dark-blue;
    border: 1px solid rgba(21,34,55,0.2);
    font-size: 16px;
    line-height: 22px;
  }

  .form-control:focus {
    border: 1px solid $th-blue;
    outline: 0;
  }

  textarea {
    height: 180px;
    width: 100%;
    resize: none;
    outline: none;
    border-radius: 3px;
    padding: 20px;
    //margin-bottom: 10px;
    border: 1px solid rgba(21,34,55,0.2);
    &:focus {
      border: 1px solid $th-blue;
    }

  }
  .form-input {
    margin-bottom: 30px;
  }
  .form-label {
    margin-bottom: 10px;
  }
}