// Theme variables //
$white: #ffffff;
$black: #000000;
$th-indigo: #162336;
$th-dark-blue: #1F3150;
$th-blue: #0067A9;
$th-grey: #E7ECF0;
$fl-nav-grey: #525252;
$th-light-blue: #F2F7FA;
$sheer: rgba(255,255,255,0.6);
$extra-sheer: rgba(255,255,255,0.2);
$grey-border: 1px solid rgba(31,49,80,0.1);
$extra-sheer-blue: rgba(0,103,169,0.2);
$sheer-blue: rgba(0,103,169,0.6);

// Bootstrap variables //
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 993px,
        xl: 1250px) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1230px
) !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
          0: 0,
          1: ($spacer * .5),
          2: ($spacer * .75),
          3: $spacer,
          4: ($spacer * 1.5),
          5: ($spacer * 2),
          6: ($spacer * 2.5)
  ),
  $spacers
);
