#map {
  width: 100%;
  height: 460px;

  .gmnoprint.gm-bundled-control {
    top: 0 !important;

    div {
      background-color: transparent !important;
    }
  }

  .gm-control-active {
    border-radius: 3px !important;
    width: 42px !important;
    height: 42px !important;
    border: 1px solid $th-blue !important;
    background-color: $white !important;
    position: relative !important;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      display: none !important;
    }

    &.gm-fullscreen-control {
      position: absolute !important;
      right: -2px !important;
      top: 86px!important;

      &::after {
        background-image: url("../../assets/img/zoom-out.svg");
      }
    }

    //button[title="Zoom in"]::after {
    //  background-image: url("../../assets/img/minus.svg");
    //}

    button[title="Zoom in"] {
      background-color: green!important;
    }

    button[title="Zoom out"]::after {
      background-image: url("../../assets/img/plus.svg");
      z-index: 5;
    }

  }
  .gm-style-cc {
    display: none!important;
  }
}

.gm-style a {
  img{
    display: none!important;
  }
}

button[title="Zoom in"]::after {
  background-image: url("../../assets/img/plus.svg");
}

button[title="Zoom out"]::after {
  background-image: url("../../assets/img/minus.svg");
  z-index: 5;
}

.map-column {
  @include media-breakpoint-down(md){
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
}