$light-color: #c9e7f8;
$base-color: #7cb3da;
$dark-color: #005ea0;

.svg_light_fill {
    fill: $light-color;
}

.svg_base_fill {
    fill: $base-color;
}

.svg_dark_fill {
    fill: $dark-color;
}

.fill_evenodd {
    fill-rule:evenodd;
}

.clip_evenodd {
    clip-rule:evenodd;
}

.svg_shipping {
    fill: none;
    fill-rule: evenodd;
    stroke: $light-color;
    stroke-miterlimit: 10;
    stroke-width: 2.18px;
}