.services-list {
  margin: 0;
  margin-left: 10px;
  padding: 0 1em 13px 1em;
  list-style: none;

  li::before {
    content: "";
    border-color: transparent $th-indigo;
    border-style: solid;
    border-width: 0.25em 0 0.25em 0.35em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 1em;
    position: relative;
  }
}
