input[type="radio"] {
    display: none;

}

input[type="radio"]:checked+label, input[type="radio"]:hover+label {
  cursor: pointer;
    p {
        color: $th-blue;
    }
}

input[type="radio"]:checked+label {
  p {
    text-decoration: underline;
  }
}

.language-radio {
    margin-top: 26px;
    img {
        width: 25px;
        height: 25px;
        position: relative;
      &::after {
        content: "";
        border: 1px solid $th-blue;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }

    }
}
