#accordion {
  width: 100%;
}
.dropdown-wrap {
  border-bottom: $grey-border;
  @include media-breakpoint-down(md){
    padding: 30px 0;
    &:last-of-type {
      border-bottom: none;
    }
  }
  @include media-breakpoint-down(sm){
    padding: 20px 0;
  }
  @include media-breakpoint-down(xs){
    padding: 10px 0;
  }
}
.accordion-btn {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;


  .accordion-button {
    width: 26px;
    height: 26px;
    border: 0;
    position: relative;

    span {
      position: absolute;
      transition: 300ms;
      background: $th-dark-blue;
      border-radius: 2px;
      opacity: 1;
      &:active, &:hover {
        opacity: 1;
        background-color: $white;
      }
    }

    span:first-child {
      top: 0;
      bottom: 0;
      width: 1px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);

    }

    span:last-child {
      left: 50%;
      right: 50%;
      height: 1px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
    }

    &:hover span:last-child {
      left: 50%;
      right: 50%;
    }
  }
  &.collapsed {
    .accordion-button {
      span {
        transform: rotate(0);
        &:last-child {
          left: 0;
          right: 0;
        }
      }
    }
  }
}

.web-nav-dropdown {
  position: relative;
  .dropdown-toggle {
    &:after {
      content: none;
    }
  }
  .dropdown-menu {
    margin-top: -1px;
    // right: 0;
    border: none;
    padding: 50px 0 57px 0;
    border-radius: 0;
  }
  //h5.swiper-bullet-text {
  //  font-size: 16px;
  //  line-height: 22px;
  //}
  div.swiper-bullet-text {
    font-size: 16px;
    line-height: 22px;
  }
  div.swiper-bullet-text {
    font-size: 16px;
    line-height: 22px;
  }

  .web-nav-dropdown-item:hover {
    svg * {
      stroke: $th-blue;
    }
    .swiper-bullet-text {
      color: $th-blue;
    }
    .link-button {
      opacity: 1;
      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }
}

.dropdown {
    .rotated {
        transform: rotate(180deg);
        transition-duration: 0.3s; 
        transition-delay: 0.3s;
    }
}

.web-nav-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  margin: .125rem 0 0;
  margin-top: -1px;
  border: none;
  padding: 0.75rem 0.175rem ;
  line-height: 2;
  border-radius: 0;
  font-size: 16px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  
}

.web-nav-dropdown-menu .web-nav-dropdown-item:not(:last-child):after{
    content: '';
    height: 1px;
    width: 75px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $fl-nav-grey;
    opacity: 0.8;
}

.web-nav-dropdown-item {
    color: $fl-nav-grey;
    position: relative;
}

.mob-nav-dropdown-item {
    color: $fl-nav-grey;
}


#menuAccordion {
  button {
    position: relative;
    &::after {
      content: "";
      display: block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: 0.4em solid;
      border-right: 0.35em solid transparent;
      border-bottom: 0;
      border-left: 0.35em solid transparent;
      right: 10px;
      top: 50%;
      position: absolute;
      transform: rotate(180deg);
      transition: all 0.3s;
      color: $th-indigo;
      margin-top: -3px;
    }
    &.collapsed {
      &:after {
        transform: rotate(0);
      }
    }
  }

}
