footer {
  padding-top: 60px;
  padding-bottom: 22px;
  @include media-breakpoint-down(md){
    padding-top: 40px;
    padding-bottom: 40px;
  }
  * {
    color: $white;
  }

}