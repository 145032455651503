.numbers-section {
  position: relative;
  overflow: hidden;
  &:not(.about){
    padding-bottom: 228px;
    @include media-breakpoint-down(sm){
      padding-bottom: 42px;
    }
  }
  * {
    color: $white;
  }

  .numbers-section-block {
    position: relative;
    z-index: 5;
    padding-top: 30px;
    border-top: 1px solid $white;
    margin-bottom: 40px;
    padding-right: 10px;
    @include media-breakpoint-down(md) {
      padding-top: 20px;
      .title {
        margin-bottom: 10px;
      }
    }
  }

  .numbers-section-about {
    padding-top: 70px;
    z-index: 5;
    @include media-breakpoint-down(md){
      order: 2;
      padding-top: 22px;
      h1 {

      }
    }

  }

  .numbers-section-img {
    z-index: 2;
    padding-top: 70px;
    img {
      object-fit: contain;
      max-width: 100%;
    }
    @include media-breakpoint-down(md){
      order: 1;
    }
  }

  .title-lg {
    @include media-breakpoint-down(sm){
      font-size: 28px;
      line-height: 38px;
    }
  }

  &:after {
    content: "";
    background: url("../../assets/img/background-line.svg");
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    bottom: 30px;
    z-index: 3;
    left: 0;
    right: 0;
    background-size: contain;
    @include media-breakpoint-down(sm){
     width: 310%;
      background-position: top;
      top: 200px;
      left: -190px;
      z-index: 0;
    }
  }
  &:before {
    content: "";
    background: url("../../assets/img/dashed-background-line.svg");
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    bottom: 30px;
    z-index: 1;
    left: 0;
    right: 0;
    background-size: contain;
    @include media-breakpoint-down(sm){
      width: 310%;

      background-position: top;
      top: 50px;
      left: -190px;
    }
  }

  .pseudo {
    &:before {
      content: "";
      background: url("../../assets/img/dark-background-line.svg");
      position: absolute;
      height: 100%;
      background-repeat: no-repeat;
      background-position: bottom;
      bottom: 0;
      z-index: 1;
      left: 35%;
      right: 0;
      background-size: contain;
      @include media-breakpoint-down(sm){
        width: 310%;

        background-position: top;
        top: 100px;
        left: -190px;
      }
    }
  }
  &.about {
    &:after {
      top: 240px;
    }
    .numbers-section-img {
      padding-top: 0;
    }
  }
}