label.checkbox-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0;
  margin-bottom: 30px;
  flex-shrink: 0;
}

.checkbox-wrap input {
  display: none;
}

.checkbox-label .checkmark {
  border: 1px solid $black;
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 4px;
}

.checkbox-wrap input:checked ~ label.checkbox-label .checkmark {
  background-color: $black;;
  &:after {
    display: block;
  }
}

.checkbox-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 12px;
  top: 5px;
  width: 8px;
  height: 18px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-wrap .label-text {
  max-width: 80%;
  white-space: break-spaces;
}