.button {
  outline: none;
  box-shadow: none;
  padding: 17px 30px;
  border-radius: 8px;
  background-color: $th-blue;
  border: 1px solid $th-blue;
  color: $white;
  transition: all 0.3s;
  white-space: nowrap;
  font-size: 16px;
  &:hover {
    background-color: transparent;
    color: $th-dark-blue;
  }
  &.transparent {
    background-color: transparent;
    color: $th-dark-blue;
    &:hover {
      background-color: $th-blue;
      color: $white;
    }
  }
  &:disabled {
    background-color: $th-grey;
    color: $white;
    border: $grey-border;
  }

  &.nav-button {
    padding: 17px 20px;
    span {
      color: $white;
    }
    &:hover {
      span {
        color: $th-dark-blue;
      }
      svg * {
        stroke: $th-dark-blue;
      }

    }
    @include media-breakpoint-down(lg){
      justify-content: center;
    }
  }
  &.submit-button {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  &.in-blue-bg {
    &:hover {
      color: $white;;
    }
  }
}

.link-button {
  white-space: nowrap;
  display: flex;
  @media (max-width: 380px) {
    p {
      font-size: 14px;
      max-width: 80%;
    }
  }
  &:hover {
    p {
      color: $th-dark-blue;
    }
    svg * {
      stroke: $th-dark-blue;
    }

  }
}

.btn-light {
  background-color: transparent;
}

.not-a-button {
  border: none;
  outline: none;
  background-color: transparent;
  &:not(.collapsed){
    color: $th-blue !important;
  }
  &.web-nav-dropdown-toggle {
    @include media-breakpoint-down(lg){
      width: 100%;
      text-align: start;
    }
  }
}



