.custom-bullet {
  width: unset;
  height: unset;
  border-radius: 0;
  background-color: transparent;
  opacity: 1;
  margin-left: 0!important;
  margin-right: 0!important;
  svg {
    width: 42px;
    margin-bottom: 25px;
    @media (max-width: 389px){
      margin-bottom: 15px;
      width: 32px;
    }
  }
  &:last-of-type {
    svg {
      width: 35px;
      @media (max-width: 389px){
        width: 27px;
      }
    }
  }
  &:hover, &.active {
    svg * {
      stroke: $th-blue;
    }
    .swiper-bullet-text {
      color: $th-blue;
    }

  }
  &:last-of-type {
    // margin-top: -10px!important;
  }
}
.swiper-image-wrap {

  width: 540px;
  height: 450px;
  @include media-breakpoint-down(lg){
    width: 377px;
    height: 314px;
  }
  @include media-breakpoint-down(xs){
    width: 268px;
    height: 222px;
  }

  img {
    object-fit: contain!important;
  }

  .layermask {
    width: 542px;
    height: 452px;
    position: absolute;
    top: -1px;
    bottom: -0.5px;
    left: -1px;
    right: -0.5px;
    -webkit-box-shadow: 1px 1px 0px 10px #FFFFFF;
    box-shadow: 2px 2px 0px 10px #FFFFFF;
    @include media-breakpoint-down(lg){
      width: 379px;
      height: 317px;
    }
    @include media-breakpoint-down(xs){
      width: 269px;
      height: 224px;
    }
  }
}

.landing-swiper {
  .swiper-wrapper {
    padding-bottom: 80px;
    @include media-breakpoint-down(xs) {
      padding-bottom: 35px;
    }
  }
  .pagination {
    min-width: 0;
    justify-content: space-between;
    @include media-breakpoint-down(xs){
      flex-wrap: wrap;
    }
    @media (max-width: 520px) {
      justify-content: start;
    }
    @media (max-width: 329px){
      justify-content: center;
    }
    .custom-bullet:first-of-type {
      display: none;
      position: absolute;
    }
  }
  video {
    max-width: 99.7%;
    height: 99.7%;
    object-fit: fill;
    object-position: right;
    @include media-breakpoint-down(lg){
      width: 377px;
      height: 314px;
    }
    @include media-breakpoint-down(xs){
      width: 268px;
      height: 222px;
    }
  }
}