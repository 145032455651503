.bootstrap-select {
  width: 100%!important;
  padding: 0!important;
  background-color: $white;

  button.dropdown-toggle {
    padding: 0;
    width: 100%;
    height: 100%;
    &:after {
      margin-right: 20px;
    }
    &:active, &:hover, &:focus, &:focus-visible {
      background-color: $white!important;
      border: none;
      outline: none!important;
      box-shadow: none;
    }

  }
  .filter-option {
    display: flex;
    align-items: center;
  }

  .filter-option-inner-inner {
    @extend .text;
    margin-left: 20px;

  }
  &.show, &:focus {
    .dropdown-toggle {
      border: 1px solid $th-blue;
      background-color: $white;
    }
  }
  .dropdown-menu {
    border-radius: 0;
    margin-top: 1px;
    padding-bottom: 0;

    .dropdown-item.active, .dropdown-item:hover {
      background-color: $white;
      .text {
        color: $th-blue!important;
      }

    }
  }
}

.language-select {
  .dropdown-toggle {
    cursor: pointer;
    border: none!important;
    &::after {
      content: none;
    }
    &:focus {
      box-shadow: none!important;
    }

  }
  .dropdown-menu {
    min-width: unset!important;
    width: 160px;
    left: unset;
    right: 0;
    padding: 10px 20px 0 20px;
    margin: 3px 0 0 0;

    .inner {
      overflow-y: unset!important;
    }
  }
  .dropdown-option {
    padding: 0;
    margin: 0;
    img {
      width: 25px;
      height: 25px;
    }
  }
  li {
    margin-bottom: 10px;
  }
  .filter-option-inner-inner {
    p {
      display: none;
    }
  }
.form-label:hover {
  transition: 0.3s;
  color: $th-blue;
}
}

