@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNext-Medium-06.ttf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNext-Regular-08.ttf") format("opentype");
  font-weight: 400;
}
